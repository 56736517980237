<template>
  <div class="wrap">
    <div class="nav">
      <div class="tabs">
        <div class="tabs_nav">
          <div class="tab_item" v-for="(item, index) in tradeMethods" :key="`trade_${index}`"
            :class="{ tab_active: trade_method === item.value }" :style="(theme == 'light' && trade_method === item.value ) ? { color: 'var(--btnsizeColor)' } : {}" @click="tradeMethodChange(item, index)">
            {{ item.title | lan }}
          </div>
        </div>
      </div>
      <div class="right_bar">
        <div class="unit_bar">
          <img :src="require('@/assets/Img/p2p/CAD.png')" alt="">
          <div class="unit_text">{{ "CAD" }}</div>
          <i class="iconfont icon-xiangxia1"></i>
        </div>
        <!-- <SelectCurrency v-model="unit" style="width: fit-content" @onConfirm="unitChange">
          <div class="unit_bar">
            <div class="unit_text">{{ unitText }}</div>
            <i class="iconfont icon-xiangxia1"></i>
          </div>
        </SelectCurrency> -->
        <!-- <i class="iconfont icon-lishijilu"></i> -->
      </div>
    </div>
    <Tabs v-model="currentCoinType" sticky title-inactive-color="var(--timeColor)" title-active-color="var(--timeColor)"
      background="var(--marqueeItemColor)">
      <Tab v-for="(item, index) in coins" :key="`coin_${index}`" :name="item.value">
        <template #title>
          <div class="tab_title">
            <img :src="require(`@/assets/Img/p2p/${item.value}.png`)" alt="">
            <span>{{item.value}}</span>
          </div>
        </template>
        <TypeList :trade_method="trade_method" :coin_type="currentCoinType" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import SelectCurrency from "@/components/currencyPicker.vue";
import { Tabs, Tab, List } from "vant";
import { $post, $get, dealError } from "@/utils";
import { mapGetters } from "vuex";
import tradeMethods from "./tradeMethods.json";
import TypeList from "./components/list.vue"
export default {
  components: {
    SelectCurrency,
    Tabs,
    Tab,
    List,
    TypeList
  },
  data() {
    this.tradeMethods = tradeMethods;
    return {
      trade_method: this.tradeMethods[0].value,
      unit: "",
      currentCoinType: "",
      getListLoading: false,
    };
  },
  computed: {
    unitText() {
      let res = {};
      if (!!this.unit) {
        res = this.$store.getters.unitData.find(
          (item) => item.code === this.unit
        );
      } else {
        const { currency_code } = this.$store.getters.userInfo;
        if (currency_code) {
          res =
            this.$store.getters.unitData.find(
              (item) => item.code === currency_code
            ) || {};
        } else {
          res = this.$store.getters.unitData[0] || {};
        }
      }
      res.code && (this.unit = res.code);
      return res.title || "";
    },
    ...mapGetters(["coins","theme"]),
    getListFinish() {
      return !this.$store.getters.coinListHasMore(this.trade_method);
    },
    coinLists() {
      return this.$store.getters.coinList(this.trade_method);
    },
  },
  created() {
    this.getList();
    this.$store.commit("REFRESH_ACCOUNT");
  },
  methods: {
    tradeMethodChange(item, index) {
      if (item.value === this.trade_method) return;
      this.trade_method = item.value;
    },
    async unitChange(value, index) {
      this.unit = value.code;
      try {
        const { ret, data, msg } = await $post("/users/edit", { currency_code: value.code });
        if (ret == 1) {
          this.$store.dispatch("getUserInfo");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getList() {
      await this.$store.dispatch("getCoinsLists", { type: this.trade_method });
      this.getListLoading = false;
    },
    toDetail(item) {
      this.$router.push(`/coinTradeDetail?type=${this.trade_method}&coinType=${this.currentCoinType}&id=${item.id}`)
    }
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.wrap {
  @navPadding: calc(13rem / 16);
  background-color: var(--marqueeItemColor);
  .nav {
    @navHeight: calc(49rem / 16);
    height: @navHeight;
    background: var(--nav-background);
    padding: 0 @navPadding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .tabs {
      height: @navHeight;
      display: flex;
      align-items: center;
      color: var(--timeColor);

      .tabs_nav {
        border-radius: calc(20rem / 16);
        display: flex;
        align-items: center;
        background-color: var(--marqueeItemColor);
      }

      .tab_item {
        margin-left: calc(8rem / 16);
        font-size: calc(15rem / 16);
        padding: 0 calc(24rem / 16);


        &:nth-child(1) {
          margin-left: 0;
        }
      }

      .tab_active {
        // color: #fff;
        color: var(--btnsizeColor);
        font-size: calc(18rem / 16);
        background-color: var(--newBtn);
        width: 100%;
        padding: calc(8rem / 16) calc(24rem / 16);
        border-radius: calc(20rem / 16);
      }
    }

    .right_bar {
      position: absolute;
      height: @navHeight;
      right: @navPadding;
      top: 0;
      display: flex;
      align-items: center;

      .unit_bar {
        display: flex;
        align-items: center;
        color: var(--theme);
        img{
          width: calc(20rem / 16);
          height: calc(20rem / 16);
          margin-right: calc(6rem / 16);
        }
        .unit_text {
          margin-right: calc(5rem / 16);
          font-size: calc(15rem / 16);
        }

        .iconfont {
          font-size: calc(0rem / 16);
        }
      }

      >.iconfont {
        font-size: calc(32rem / 16);
        color: #fff;
        margin-left: calc(10rem / 16);
        margin-right: calc(-6rem / 16);
      }
    }
  }

  /deep/ .van-tabs__nav {
    .van-tabs__line {
      background-color: var(--timeColor);
    }
  }
  .tab_title{
    display: flex;
    align-items: center;
    img{
      width: calc(20rem / 16);
      height: calc(20rem / 16);
      margin-right: calc(6rem / 16);
    }
  }
  .list_wrap{
    // background-color: var(--marqueeItemColor);
  }
}</style>
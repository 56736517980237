<template>
  <List @load="getList" v-model="loading" :finished="!hasMore" :finished-text="$t('Hint.notMore')"
    :loading-text="$t('Hint.loading')" class="list_wrap" :class="trade_method">
    <div class="coin_list_item_wrap" :style="theme == 'light'? {borderBottom: '1px solid rgba(0,0,0,.2)'}:{}" v-for="(item, index) in lists" :key="`coin_list_item_${index}`"
      @click="toDetail(item)">
      <div class="line">
        <div class="title">
          <span class="text">{{ item.title }}</span>
          <i class="iconfont icon-iconfontanquan" style="color: #29C59C;"></i>
        </div>
        <div class="orders_order_rate">
          <span class="num">
            {{ $t("p2p.coinList.orders", { __: item.order_num }) }}
          </span>
          <span class="rate">
            {{ `${item.order_rate}%` }}
          </span>
        </div>
      </div>
      <div class="line">
        <div class="left">
          <span class="label">{{ $t("p2p.coinList.label.amount") }}</span>
          <span class="value">{{ `${item.amount} ${item.coin_type}` }}</span>
        </div>
        <div class="price_label">{{ $t("p2p.coinList.label.price") }}</div>
      </div>
      <div class="line">
        <div class="left">
          <span class="label">{{ $t("p2p.coinList.label.limit") }}</span>
          <span class="value">{{ item.limit }}</span>
        </div>
        <div class="price_value">{{ $money(item.price, true, "C$") }}</div>
      </div>
      <div class="line align_item">
        <div class="img_icon">
          <img :src="require('@/assets/Img/p2p/transfer.png')" alt="">
          <i class="iconfont icon-yinhangqia"></i>
        </div>
        <div class="btn_trade" :class="item.trade_method == 'buy' ? 'buyBtn' : 'sellBtn'"
          :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : {}">
          {{ item.trade_method === 'buy' ? "Buy" : "Sell" }}
        </div>
      </div>
    </div>
  </List>
</template>

<script>
import { List } from "vant";
import { $get } from "@/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    List
  },
  props: {
    trade_method: {
      type: String,
      default: "buy"
    },
    coin_type: {
      type: String,
      default: "USDT"
    }
  },
  data() {
    return {
      loading: false,
      hasMore: true,
      page: 0,
      pageSize: 10,
      lists: [],
      mounted: false
    }
  },
  watch: {
    trade_method() {
      if (this.mounted) {
        this.page = 0;
        this.lists = [];
        this.getList();
      }
    },
    coin_type() {
      if (this.mounted) {
        this.page = 0;
        this.lists = [];
        this.getList();
      }
    }
  },
  created() { },
  methods: {
    async getList() {
      this.loading = true;
      this.page++;
      try {
        const { data } = await $get("/ptop/lists", { trade_method: this.trade_method, coin_type: this.coin_type, page: this.page });
        const { currentPage, lists, hasMorePages } = data;
        this.loading = false;
        this.hasMore = hasMorePages;
        const listData = lists || [];
        if (currentPage == 1) {
          this.lists = listData;
        } else {
          this.lists = [...this.lists, ...listData]
        }
        !this.mounted && (this.mounted = true)
      } catch (error) {
        console.log(error, ">>>");
        this.page--;
      }
    },
    toDetail(item) {
      if (item.trade_show == -1) return;
      this.$router.push(`/coinTradeDetail?type=${this.trade_method}&coinType=${this.coin_type}&id=${item.id}`)
    }
  },
  computed:{
    ...mapGetters(["theme"]),
  },
}
</script>

<style lang="less" scoped>
@navPadding: calc(13rem / 16);
@Color: var(--timeColor);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--timeColor);

.list_wrap {
  background: #fff;
  // background: @bg;
  padding: 0 @navPadding;

  .coin_list_item_wrap {
    padding: calc(15rem / 16) 0;
    border-bottom: 1px solid @sizeColor;

    .align_item {
      align-items: flex-end;
    }

    .line {
      display: flex;
      justify-content: space-between;
      font-size: calc(14rem / 16);
      margin-top: 8px;

      &:nth-child(1) {
        margin-top: 0;
      }

      .grey {
        // color: #999;
        color: @sizeColor;
      }

      .title {
        font-size: calc(16rem / 16);
        color: @sizeColor
      }

      .orders_order_rate {
        .grey();

        .num {
          padding-right: 3px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          margin-right: 6px;
        }
      }

      .left {
        .label {
          .grey();
          margin-right: 5px;
        }

        .value {
          color: @Color
        }
      }

      .price_label {
        .grey();
      }

      .price_value {
        // color: var(--trade-green);
        font-size: calc(16rem / 16);
        color: @Color;
      }

      .img_icon {

        >img {
          @size: calc(24rem / 16);
          width: @size;
          margin-right: calc(6rem / 16);
        }

        .iconfont {
          color: @Color;
          font-size: calc(32rem / 16);
        }
      }

      .btn_trade {
        // background: var(--trade-green);
        // color: #fff;
        background: var(--greenColor);
        // background: @background;
        // color: @Color;
        color: @sizeColor;
        padding: calc(8rem / 16) calc(20rem / 16);
        border-radius: 4px;
      }
    }
  }
}

.buy {

  .price_value {
    // color: var(--trade-green);
    color: @Color;
  }

  .btn_trade {
    // background-color: var(--trade-green);
    background: var(--greenColor);
    color: @Color;
  }
}

.buyBtn {
  background: var(--greenColor);
  color: @sizeColor;
}

.sellBtn {
  background: var(--trade-red) !important;
  color: @sizeColor !important;
}

.sell {

  .price_value {
    color: var(--trade-red);
  }

  .btn_trade {
    background-color: var(--trade-red);
  }
}</style>